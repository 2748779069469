import React from "react"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"
import { graphql, StaticQuery } from "gatsby"
import HomeContacto from "../components/home-contacto"
import Escaleras from "../images/escaleras1-bw.png"

const ContactoPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          datoCmsPaginaContactoCdp {
            titulo
            contenido
            imagen {
              url
            }
          }
        }
      `}
      render={data => (
        <Layout pageTitle="Contacto y Dirección">
          <div className="w-full lg:w-10/12 mx-auto">
            <Navigation2 />

            <div className="w-full lg:flex mx-auto">
              <div className="hidden lg:block w-full lg:w-1/3 py-3 px-5 ">
                <img
                  src={data.datoCmsPaginaContactoCdp.imagen.url}
                  alt="CDP"
                  className="p-5 pl-0 w-full object-cover"
                />
              </div>
              <div className="w-full lg:w-2/3 px-5 lg:px-0 mt-10 lg:mt-0">
                <h1 className="text-3xl">
                  {data.datoCmsPaginaContactoCdp.titulo}
                </h1>

                <div
                  dangerouslySetInnerHTML={{
                    __html: data.datoCmsPaginaContactoCdp.contenido,
                  }}
                />
              </div>
            </div>
          </div>

          <HomeContacto />
        </Layout>
      )}
    />
  )
}

export default ContactoPage
